import React from 'react';
import { styled, theme } from '../../Theme';
import { StyledProps } from '@glitz/react';
import { media } from '@glitz/core';

type PropType = StyledProps & {
  name: string;
};

function ContentTag({ name, compose }: PropType) {
  return (
    <Content css={compose()}>
      <Name>{name}</Name>
    </Content>
  );
}

export default styled(ContentTag);

const Content = styled.div({
  bottom: theme.none,
  left: theme.none,
  backgroundColor: theme.mediumAqua,
  padding: { x: theme.spacing(4), y: theme.spacing(2) },
  ...media(theme.mediaQuery.mediaMaxMedium, {
    padding: { xy: theme.spacing(1) },
  }),
});

const Name = styled.p({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  font: {
    size: theme.alpha,
    weight: theme.fontWeight.normal,
  },
  color: theme.blueDark,
});
