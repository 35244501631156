import React, { useRef } from 'react';
import IProductCardModel from '../ProductCard/Models/ProductCardModel.interface';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import KexLink from '../Shared/KexLink/KexLink';
import Image from '../Shared/Image/Image';
import preset from '../Shared/Common/ImagePresets';
import ContentTag from '../Shared/ContentTag/ContentTag';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';

type ProductCardType = {
  item: IProductCardModel;
  inEditMode?: Boolean;
};

function ProductCard({
  item: { image, name, code, url, mainCategory, specifications, promotion },
}: ProductCardType) {
  const {
    translations: { 'common/campaign': campaign },
  } = useAppSettingsData();
  const lazyLoadRef = useRef<HTMLDivElement>(null);
  return (
    <StyledKexLink href={url} key={code}>
      <Card ref={lazyLoadRef}>
        <ImageContainer>
          <ImageWrapper>
            <ProductImage
              externalRef={lazyLoadRef}
              src={image ? image.url + preset.product.card : ''}
              alt={image ? image.alt : ''}
            />
          </ImageWrapper>
          {promotion && <ContentTag name={campaign} />}
        </ImageContainer>

        <ProductCardInfo>
          <ProductCategory>{mainCategory}</ProductCategory>
          <ProductName>{name}</ProductName>
          {specifications?.specificationModelList?.map(
            (specification, index) =>
              specification && (
                <Specification key={index + code}>
                  {specification?.values?.length !== 0 &&
                    specification.name + ': ' + specification.values}
                </Specification>
              )
          )}
        </ProductCardInfo>
      </Card>
    </StyledKexLink>
  );
}

const ImageContainer = styled.div({
  width: '100%',
  height: 'auto',
  position: 'relative',
});

const StyledKexLink = styled(KexLink, {
  display: 'flex',
  width: '100%',
  height: '100%',
  scrollSnapAlign: 'start',
  position: 'relative',
  ':hover': {
    ':after': {
      pointerEvents: 'none',
      content: '""',
      display: 'block',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,

      border: {
        xy: {
          width: theme.spacing(0.5),
          style: 'solid',
          color: theme.thinGray,
        },
      },
      padding: { xy: theme.spacing(4) },
    },

    cursor: 'pointer',
  },
  minWidth: theme.none,
});

const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.white,
  cursor: 'pointer',
  alignItems: 'start',
  textAlign: 'center',
  width: '100%',
  height: '100%',
  ...media(theme.mediaQuery.mediaMinMedium, {
    padding: { y: theme.spacing(4), x: theme.spacing(2) },
  }),
});

const ProductCardInfo = styled.div({
  marginTop: theme.spacing(4),
  display: 'flex',
  letterSpacing: theme.letterSpacing.moreWide,
  fontWeight: theme.fontWeight.normal,
  fontSize: theme.alpha,
  color: theme.black,
  flexDirection: 'column',
  textAlign: 'left',
});

const ImageWrapper = styled.div({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  width: '100%',
  paddingTop: theme.ratioDesktop,
  alignItems: 'center',
  overflow: 'hidden',
});

const ProductImage = styled(Image, {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  maxHeight: '100%',
  ':hover': {
    maxHeight: '200%',
    transition: {
      property: 'max-height',
      duration: theme.timings.threeFifths,
    },
  },
  margin: { y: 0, x: 'auto' },
});

const ProductName = styled.p({
  color: theme.blueDark,
  fontSize: theme.delta,
  fontWeight: theme.fontWeight.normal,
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.wide,
  margin: {
    y: theme.spacing(1),
  },
  ...media(theme.mediaQuery.mediaMinMedium, {
    fontSize: theme.epsilon,
    fontWeight: theme.fontWeight.bold,
  }),
});

const ProductCategory = styled.p({
  fontSize: theme.alpha,
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.wider,
  marginTop: 'auto',
});

const Specification = styled.p({
  marginTop: theme.spacing(1),
  ...media(theme.mediaQuery.mediaMinMedium, { fontSize: theme.spacing(3.5) }),
});

export default ProductCard;
