import React from 'react';
import { styled, theme } from '../../Theme';
import { media } from '@glitz/core';
import { FeedbackButton } from '../Button/Button';

type PropType = {
  loaded: number;
  counterText: string;
  loadMore: string;
  disableButton?: boolean;
  action: () => Promise<any>;
  totalAmount?: number;
  TAKE: number;
};

const LoadMoreContainer = ({
  loaded,
  counterText,
  loadMore,
  disableButton = false,
  totalAmount = 0,
  TAKE,
  action,
}: PropType) => {
  return (
    <LoadContainer>
      {totalAmount > TAKE && (
        <>
          <ProgressBar>
            <Loaded css={{ width: loaded ? `${loaded}%` : '0' }} />
          </ProgressBar>

          <Counter>{counterText}</Counter>
        </>
      )}
      <LoadButton disabled={disableButton} action={action}>
        <ButtonTextWrapper> {loadMore} </ButtonTextWrapper>
      </LoadButton>
    </LoadContainer>
  );
};

export default LoadMoreContainer;

const LoadContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  padding: {
    y: theme.spacing(5),
    x: theme.spacing(4),
  },
  margin: { x: 'auto', bottom: theme.spacing(16) },
});

const Counter = styled.span({
  font: { size: theme.alpha, weight: theme.fontWeight.normal },
  color: theme.progressBarText,
  letterSpacing: theme.letterSpacing.nearNormalWide,
  lineHeight: theme.lineHeight.normal,
  margin: { top: theme.spacing(4), bottom: theme.spacing(4) },
});

const LoadButton = styled(FeedbackButton, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.white,
  width: '100%',
  padding: { x: theme.spacing(4) },
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  color: theme.blueDark,
  border: { xy: { width: theme.spacing(0.5), color: theme.blueDark } },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { x: theme.spacing(8), y: theme.spacing(4) },
    width: 'auto',
  }),
});

const ButtonTextWrapper = styled.div({
  fontWeight: theme.fontWeight.bold,
});

const ProgressBar = styled.div({
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: theme.spacing(2),
  borderRadius: theme.none,
  backgroundColor: theme.thinGray,
  ...media(theme.mediaQuery.mediaMinSmall, {
    width: 360,
  }),
});

const Loaded = styled.span({
  backgroundColor: theme.loadMoreBar,
  top: theme.none,
  left: theme.none,
  width: '0',
  borderRadius: theme.none,
  transition: {
    duration: theme.timings.one,
    timingFunction: theme.animation.timingFn,
  },
});
