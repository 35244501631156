import React, { useState } from 'react';
import { styled, theme } from '../Theme';
import VariationLightModel from '../ProductCard/Models/VariationLightModel.interface';
import PackageModel from '../KexVariation/Models/PackageModel.interface';
import { SmallButton } from '../Shared/Button/Button';
import { StyleOrStyleArray } from '@glitz/type';
import { media, pseudo } from '@glitz/core';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import ValueDropDown from '../Shared/ValueDropDown/ValueDropDown';
import IconSwitch from '../Shared/Icons/IconsSwitch';
import { UpdateCart } from '../Cart/Cart';
import { QntInput } from '../ProductPage/QuantityInput';
import useMedia from '../Shared/Hooks/useMedia';
import Price from '../Shared/Price/Price';
import useEncodedCurrentUser from '../Shared/Hooks/useEncodedCurrentUser';

type ProductCardType = {
  item: VariationLightModel;
  inEditMode?: Boolean;
};

function VariationListItem({ item }: ProductCardType) {
  const id = useEncodedCurrentUser();
  const {
    translations: {
      'productPage/addToCart': addToCartLabel,
      'productPage/notInStock': notInStockLabel,
      'productPage/articleNumber': articleNumberLabel,
      'productPage/eanCode': eanCodeLabel,
    },
  } = useAppSettingsData();
  const [price, setPrice] = useState<string>(
    item.sellablePackages?.find(i => i.preSelected)?.packagePrice ||
      item.sellablePackages[0]?.packagePrice ||
      ''
  );
  const [discount, setDiscount] = useState<string>(
    item.sellablePackages?.find(i => i.preSelected)?.discountedPackagePrice ||
      item.sellablePackages[0].discountedPackagePrice ||
      ''
  );
  const isMobile = useMedia(theme.mediaQuery.mediaMaxSmall);

  const [packagObj, setPackagObj] = useState<PackageModel>(
    item.sellablePackages.find(i => i.preSelected) || item.sellablePackages[0]
  );
  const [packagQnt, setPackagQnt] = useState<number>(1);

  function setCurrentPackage(currentPackage: string) {
    let packageItem = item.sellablePackages.find(element => {
      return element.description === currentPackage;
    });

    if (packageItem) {
      setPrice(packageItem?.packagePrice);
      setDiscount(packageItem?.discountedPackagePrice);
      setPackagObj(packageItem);
    }
  }

  function setQuantity(num: number) {
    setPackagQnt(num);
  }

  const addToCart = () => {
    UpdateCart(item.code, packagObj.qty, packagObj.key, packagQnt, id);
  };

  return (
    <>
      {!isMobile ? (
        <Row>
          <Column
            css={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <PackageInfoDesktop>
              {IconSwitch(item.package.key)}
              <Bold>{item.package.amountInPackage}</Bold>
              <Bold>{item.package.unit}</Bold>
              <Text>{'-'}</Text>
              <DescText>{item.package.description}</DescText>
            </PackageInfoDesktop>
            <Text>
              <b>{eanCodeLabel}:</b> {item.ean}
            </Text>
            <Text>
              <b>{articleNumberLabel}:</b> {item.articleNumber}
            </Text>
          </Column>
          <Column css={DropDown}>
            {item.sellablePackages && item.sellablePackages.length > 0 && (
              <ValueDropDown
                values={item?.sellablePackages.map(p => ({
                  icon: p.key,
                  value: p.description,
                  heading: `${p.description} - ${p.amountInPackage} - ${p.unit}`,
                }))}
                currentValue={{
                  icon: packagObj.key,
                  heading: packagObj.description,
                  subHeading: `${packagObj.amountInPackage} ${packagObj.unit}`,
                  value: packagObj.description,
                }}
                setSelectedValue={setCurrentPackage}
              />
            )}
          </Column>
          <Column css={{ display: 'flex', justifyContent: 'center' }}>
            <Price price={price} isSmall={true}></Price>
          </Column>
          <Column css={QuantityInput}>
            {!item.isOutOfStock && (
              <QntInput
                updateState={q => {
                  setQuantity(q);
                }}
                quantity={packagQnt}
                onProductPage={false}
              />
            )}
          </Column>
          <Column css={{ flexBasis: '22%' }}>
            <AddToCartButton
              onClick={() => {
                addToCart();
              }}
              disabled={item.isOutOfStock}
            >
              <ButtonText>
                {item.isOutOfStock ? notInStockLabel : addToCartLabel}
              </ButtonText>
            </AddToCartButton>
          </Column>
        </Row>
      ) : (
        <>
          <RowMobile>
            <ColumnMobile
              css={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <PackageInfoMobile>
                {IconSwitch(item.package.key)}
                <Bold>{item.package.amountInPackage}</Bold>
                <Bold>{item.package.unit}</Bold>
                <Text>{'-'}</Text>
                <Text>{item.package.description}</Text>
              </PackageInfoMobile>
              <Text>
                <b>{eanCodeLabel}:</b> {item.ean}
              </Text>
              <Text>
                <b>{articleNumberLabel}:</b> {item.articleNumber}
              </Text>
            </ColumnMobile>
            <ColumnMobile>
              {item.sellablePackages && item.sellablePackages.length && (
                <ValueDropDown
                  values={item?.sellablePackages.map(p => ({
                    icon: p.key,
                    value: p.description,
                    heading: `${p.description} - ${p.amountInPackage} - ${p.unit}`,
                  }))}
                  currentValue={{
                    icon: packagObj.key,
                    heading: packagObj.description,
                    subHeading: `${packagObj.amountInPackage} ${packagObj.unit}`,
                    value: packagObj.description,
                  }}
                  setSelectedValue={setCurrentPackage}
                />
              )}
            </ColumnMobile>
          </RowMobile>
          <RowMobile>
            <ColumnMobile>
              <Price price={price} loweredPrice={discount} isSmall />
            </ColumnMobile>
            {!item.isOutOfStock ? (
              <ColumnMobile css={QuantityInput}>
                <QntInput
                  updateState={q => {
                    setQuantity(q);
                  }}
                  quantity={packagQnt}
                  onProductPage={false}
                />
              </ColumnMobile>
            ) : (
              <styled.P
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {notInStockLabel}
              </styled.P>
            )}
          </RowMobile>
          {!item.isOutOfStock && (
            <AddToCartButtonMobile
              onClick={addToCart}
              isActive
              disabled={item.isOutOfStock}
            >
              <ButtonText>
                {item.isOutOfStock ? notInStockLabel : addToCartLabel}
              </ButtonText>
            </AddToCartButtonMobile>
          )}
        </>
      )}
    </>
  );
}

const Row = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: {
      left: theme.spacing(4),
      right: theme.spacing(4),
      top: theme.spacing(4),
    },
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    marginTop: theme.spacing(2),
  }),
});

const PackageInfoMobile = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.beta,
  textTransform: 'capitalize',
  paddingBottom: `${theme.spacing(2)}px`,
});

const Column = styled.div({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
  marginBottom: 'auto',
  marginTop: 'auto',
  height: '100%',

  ...pseudo([':nth-child(1)'], {
    marginLeft: theme.none,
    marginRight: 'auto',
    width: '25%',
  }),
  ...pseudo([':nth-child(3)'], {
    marginRight: theme.none,
    width: theme.spacing(42),
  }),
});
const ColumnMobile = styled.div({
  display: 'flex',
  flexBasis: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const RowMobile = styled.div({
  display: 'flex',
  margin: { y: theme.spacing(4) },
  maxHeight: theme.dropDownHeight,
});

const DropDown: StyleOrStyleArray = {
  width: theme.spacing(40),
};

const QuantityInput: StyleOrStyleArray = {
  margin: { xy: theme.none },
  width: theme.spacing(23),
  height: 'auto',
  alignSelf: 'stretch',
  ...media(theme.mediaQuery.mediaMaxSmall, {
    flexBasis: '20%',
    margin: { xy: 0 },
  }),
};

const Text = styled.span({
  padding: { left: theme.spacing(2) },
  fontSize: theme.beta,
});

const DescText = styled(Text, {
  textTransform: 'capitalize',
});

const Bold = styled.span({
  padding: { left: theme.spacing(2) },
  fontWeight: 'bold',
});

const ButtonText = styled.span({
  font: { size: theme.gamma },
  padding: { bottom: theme.tiny },
});

const AddToCartButton = styled(SmallButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.normal },
  letterSpacing: theme.letterSpacing.medium,
  backgroundColor: theme.white,
  padding: { x: theme.spacing(5.5), y: theme.spacing(4) },
  margin: { xy: theme.none },
  color: theme.scrollBarGray,
  width: '100%',
  borderRadius: theme.none,
  border: { xy: { width: theme.none } },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  ...media(theme.mediaQuery.mediaMaxSmall, {
    backgroundColor: theme.blueDark,
    color: theme.white,
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { left: theme.spacing(4) },
    ':hover': {
      color: theme.white,
      backgroundColor: theme.blueDark,
    },
  }),
});

const AddToCartButtonMobile = styled(AddToCartButton, {
  backgroundColor: theme.blueDark,
  color: theme.white,
  margin: { xy: theme.none },
});

const PackageInfoDesktop = styled.p({
  display: 'flex',
  alignItems: 'center',
  marginBottom: `${theme.spacing(2)}px`,
});
export default VariationListItem;
