import React from 'react';
import { theme, styled } from '../../Theme';
import { media } from '@glitz/core';

type PropTypes = {
  children: React.ReactNode;
  maxWidth: number | string;
};

function CardGrid({ children, maxWidth, ...rest }: PropTypes) {
  return (
    <Div css={{ maxWidth: maxWidth }} {...rest}>
      {children}
    </Div>
  );
}

const Div = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: theme.spacing(2),
  margin: { x: 'auto' },
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.tiny,
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(8),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: '1fr 1fr',
    gridGap: 0,
  }),
  ...media(theme.mediaQuery.mediaMinHuge, {
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 0,
  }),
});

export default CardGrid;
