import React from 'react';
import { styled, theme } from '../Theme';
import ArrowRight from './../Shared/Icons/ArrowRightIcon';
import KexLink from '../Shared/KexLink/KexLink';
import VariationListItem from './VariationListItem';
import VariationLightModel from '../ProductCard/Models/VariationLightModel.interface';
import { media } from '@glitz/core';
import { useHeaderData } from '../Shared/Models/Headers/Header';
import ProductVariationModel from '../ProductCard/Models/ProductVariationModel.interface';

type ProductCardType = {
  item: ProductVariationModel;
  inEditMode?: Boolean;
};

function ProductList({
  item: { name, url, variationLightList },
}: ProductCardType) {
  const { headerHeight } = useHeaderData();

  return (
    <Root>
      <Header css={{ top: headerHeight }}>
        <HeaderText>{name}</HeaderText>
        <StyledKexLink href={url}>
          <ArrowRightIcon />
        </StyledKexLink>
      </Header>
      <List>
        {variationLightList?.map((variant: VariationLightModel) => (
          <VariationListItem key={variant.code} item={variant} />
        ))}
      </List>
    </Root>
  );
}

const Root = styled.div({ color: theme.black, width: '100%' });

const HeaderText = styled.p({
  display: 'inline',
});

const StyledKexLink = styled(KexLink, { margin: { y: 'auto', left: 'auto' } });

const ArrowRightIcon = styled(ArrowRight, {
  float: 'right',
  display: 'inline',
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginLeft: theme.spacing(1),
});

const Header = styled.div({
  ...media(theme.mediaQuery.mediaMaxMedium, {
    position: 'sticky',
    zIndex: theme.zIndex.PageElement,
    transition: {
      duration: theme.timings.oneTenth,
      property: 'all',
    },
  }),
  padding: {
    xy: theme.spacing(4),
  },
  backgroundColor: theme.thinGray,
  fontWeight: theme.fontWeight.bold,
  display: 'flex',
});
const List = styled.div({ marginBottom: theme.spacing(8) });

export default ProductList;
